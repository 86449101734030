import { openLink } from "src/utils";
import iconRight from "src/assets/images/icon_arrow_right.svg";
import iconCornerBl from "src/assets/images/icon_corner_bl.svg";
import iconCornerBr from "src/assets/images/icon_corner_br.svg";

export const Section4 = () => {
  return (
    <div className="h-[470px] flex justify-center bg-gradient-to-r from-blue-800 via-[#B26BD3] to-red-100">
      <div className="w-full-screen px-[100px] flex flex-col">
        <div>
          <div className="mt-[60px] text-white font-bold text-[40px] leading-none">
            Explore the Staking Derivative on StaFi Hub
          </div>
        </div>

        <div className="flex-1 mt-[80px] flex items-end">
          <div className="mb-[75px] flex flex-col items-center">
            <div className="text-white font-bold text-[100px] leading-none">
              rATOM
            </div>

            <div className="mt-[20px] text-[#e1e1e1] text-[20px] leading-none">
              A representation of staked ATOMs with
            </div>

            <div className="mt-[2px] text-[#e1e1e1] text-[20px] leading-none">
              attached staking rewards
            </div>

            <div
              className="mt-[30px] text-white text-[20px] leading-none flex items-center cursor-pointer"
              onClick={() =>
                openLink("https://app.stafi.io/gallery/cosmos/ATOM")
              }
            >
              Explore rATOM
              <img src={iconRight} className="ml-[10px]" alt="right" />
            </div>
          </div>

          <div className="mb-[75px] ml-[70px] flex flex-col items-center">
            <div className="text-white font-bold text-[100px] leading-none">
              rIRIS
            </div>

            <div className="mt-[20px] text-[#e1e1e1] text-[20px] leading-none">
              A representation of staked IRISs with
            </div>

            <div className="mt-[2px] text-[#e1e1e1] text-[20px] leading-none">
              attached staking rewards
            </div>

            <div
              className="mt-[30px] text-white text-[20px] leading-none flex items-center cursor-pointer"
              onClick={() =>
                openLink("https://app.stafi.io/gallery/cosmos/IRIS")
              }
            >
              Explore rIRIS
              <img src={iconRight} className="ml-[10px]" alt="right" />
            </div>
          </div>

          <div className="mb-[50px] ml-[120px] flex items-center">
            <img src={iconCornerBl} className="self-start w-6 h-6" alt="bl" />
            <div
              className="text-white text-[60px] leading-none font-bold cursor-pointer"
              onClick={() => openLink("https://app.stafi.io/gallery/cosmos")}
            >
              More
            </div>
            <img src={iconCornerBr} className="self-end w-6 h-6" alt="br" />
          </div>
        </div>
      </div>
    </div>
  );
};
