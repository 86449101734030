import { openLink } from "src/utils";

export const AppFooter = () => {
  return (
    <div className="flex justify-center bg-[#171717]">
      <div className="pl-[88px] pt-[80px] h-[300px] w-full-screen flex">
        <div>
          <div className="text-white font-bold text-[21px] leading-none">
            ABOUT
          </div>

          <div
            className="mt-[22px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://docs.stafi.io/stafihub/");
            }}
          >
            Intro
          </div>

          <div
            className="mt-[11px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://www.stafi.io/");
            }}
          >
            StaFi
          </div>
        </div>

        <div className="ml-[100px]">
          <div
            className="text-white font-bold text-[21px] leading-none"
            onClick={() => {
              openLink("https://github.com/stafihub/stafihub");
            }}
          >
            RESOURCES
          </div>

          <div
            className="mt-[22px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://github.com/stafihub/stafihub");
            }}
          >
            Github
          </div>

          <div
            className="mt-[11px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://docs.stafi.io/stafihub/");
            }}
          >
            Documentation
          </div>
        </div>

        <div className="ml-[100px]">
          <div className="text-white font-bold text-[21px] leading-none">
            Community
          </div>

          {/* <div
            className="mt-[22px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://twitter.com/StaFiHub_");
            }}
          >
            Twitter
          </div> */}

          <div
            className="mt-[11px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://discord.com/invite/jB77etn");
            }}
          >
            Discord
          </div>

          <div
            className="mt-[11px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://stafi-protocol.medium.com");
            }}
          >
            Medium
          </div>

          <div
            className="mt-[11px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://dao.stafi.io/");
            }}
          >
            Forum
          </div>
        </div>

        <div className="ml-[100px]">
          <div className="text-white font-bold text-[21px] leading-none">
            Links
          </div>

          <div
            className="mt-[22px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://www.mintscan.io/stafi");
            }}
          >
            Explorer
          </div>

          <div
            className="mt-[11px] text-[#C2C2C2] text-[17px] cursor-pointer leading-none"
            onClick={() => {
              openLink("https://www.stafi.io/getfis");
            }}
          >
            Get FIS
          </div>
        </div>
      </div>
    </div>
  );
};
