import { Section1 } from "src/components/Section1";
import { Section2 } from "src/components/Section2";
import { Section3 } from "src/components/Section3";
import { Section4 } from "src/components/Section4";
import { Section5 } from "src/components/Section5";
import { Section6 } from "src/components/Section6";
import { SectionOnChain } from "src/components/SectionOnChain";
import { VideoSection } from "src/components/VideoSection";

export const Home = () => {
  return (
    <div>
      <Section1 />

      <VideoSection />

      <Section2 />

      <SectionOnChain />

      <Section4 />

      <Section3 />

      {/* <SectionChainIntegration /> */}

      <Section5 />

      <Section6 />
    </div>
  );
};
