import stafihub from "src/assets/images/stafihub.svg";
import bg1 from "src/assets/images/bg1.png";
import spaceship from "src/assets/images/spaceship.png";
import { openLink } from "src/utils";

export const Section1 = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-[1000px] relative">
        <img
          src={spaceship}
          alt="spaceship"
          className="absolute left-[0px] top-0 w-[160px] h-[160px] z-10"
        />

        <div
          className="mt-[40px] h-[120px] backdrop-blur-[100px] rounded-[40px] flex items-center justify-between"
          style={{
            background:
              "linear-gradient(136.16deg, #6658FF -12.01%, #C990DC 76.94%)",
          }}
        >
          <div className="ml-[170px] text-white font-alibabaPuHui font-[900] text-[22px] tracking-[1.5px]">
            StaFiHub Mainnet is now live!
          </div>

          <div
            className="flex items-center justify-center text-white font-[16px] font-[700]  h-[50px] rounded-full px-[40px] mr-[50px] cursor-pointer"
            onClick={() => {
              openLink("https://app.stafi.io/gallery/cosmos");
            }}
            style={{
              background:
                "linear-gradient(139.48deg, #0093ED -16.68%, #6758FF 64.43%)",
              border: "1px solid rgba(255, 255, 255, 0.3)",
            }}
          >
            Explore
          </div>
        </div>
      </div>

      <div className="w-full-screen relative">
        <div className="z-10 mt-[120px] text-[40px] font-bold leading-none text-white flex items-center flex-col">
          <div>A Cross-Chain Staking Derivatives Platform</div>

          <div className="mt-[2px]">On The Cosmos Ecosystem</div>
        </div>

        <div className="mt-[55px] text-[18px] leading-none text-white flex items-center flex-col z-10">
          <div className="z-10">
            StaFiHub is built using the Cosmos-SDK and aims to provide liquid
            staking solutions for assets
          </div>

          <div className="z-10 mt-[2px]">
            through the Inter-Blockchain Communication (IBC) Protocol to rapidly
            grow the staking industry.
          </div>
        </div>

        <div className="mt-[88px] flex items-center flex-col z-0">
          <img
            src={stafihub}
            alt="bridge"
            className="w-[1138px] h-[100px] mb-[240px] z-10"
          />
        </div>

        <div className="w-[500px] h-[500px] absolute top-[145px] z-0 object-contain left-1/2 -translate-x-1/2 bg-gradient-to-r from-blue-800 via-[#B26BD3] to-red-100 rounded-full blur-3xl opacity-30" />
      </div>
    </div>
  );
};
