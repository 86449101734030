import liquidity from "src/assets/images/liquidity.png";
import rBridge from "src/assets/images/rBridge.png";
import rPool from "src/assets/images/rPool.svg";
import rDEX from "src/assets/images/rDEX.svg";
import iconRight from "src/assets/images/icon_arrow_right_blue.png";
import { openLink } from "src/utils";

export const Section5 = () => {
  return (
    <div className="flex justify-center pb-[50px]">
      <div className="w-full-screen px-[100px]">
        <div className="mt-[100px] w-[670px] text-white font-bold text-[40px] leading-none">
          Explore the Liquidity Solutions of the Staking Derivatives
        </div>

        <div className=" mt-[110px] flex relative">
          <div className=" flex flex-col w-[376px] h-[624px] rounded-[4px] border border-gray-300">
            <div className="mt-[34px] ml-[27px] w-[277px] text-white font-bold text-[40px] leading-none">
              Liquidity KIT for rToken
            </div>

            <img
              src={liquidity}
              className="w-[350px] mt-[80px] ml-[27px] object-cover"
              alt="liquidity"
            />
          </div>

          <div>
            <div className="ml-[27px] flex w-[671px] h-[291px] rounded-[4px] border border-gray-300 relative">
              <div className="mt-[34px] ml-[27px]">
                <div className="text-white font-bold text-[24px] leading-none">
                  rDEX-rToken Exchange
                </div>

                <div className="w-[300px] mt-[25px] text-gray-200 text-[20px] leading-none">
                  A Decentralized Exchange built exclusively for rTokens.
                </div>

                <div
                  className="absolute bottom-[33px] left-[27px] flex items-center cursor-pointer"
                  onClick={() =>
                    openLink("https://app.rdex.finance/stafihub/swap")
                  }
                >
                  <div className="text-[#909FB5] text-[16px] leading-none">
                    Explore
                  </div>
                  <img
                    src={iconRight}
                    className="mt-[1px] ml-[10px]"
                    alt="right"
                  />
                </div>
              </div>

              <img src={rDEX} className="w-[200px] ml-[80px]" alt="liquidity" />
            </div>

            <div className="flex mt-[42px]">
              <div className="ml-[27px] flex w-[330px] h-[291px] rounded-[4px] border border-gray-300 relative">
                <div className="mt-[23px] mx-[20px] ">
                  <div className="text-white font-bold text-[24px] leading-none">
                    rBridge-IBC
                  </div>

                  <div className="mt-[42px] text-gray-200 text-[20px] leading-none">
                    rBridge is a bridge that enables cross-chain swaps and
                    bridging for rTokens between multiple ecosystems to
                    designated chains.
                  </div>

                  <div
                    className="absolute bottom-[33px] left-[27px] flex items-center cursor-pointer"
                    onClick={() =>
                      openLink(
                        "https://app.stafi.io/gallery/cosmos/ATOM?tab=bridge"
                      )
                    }
                  >
                    <div className="text-[#909FB5] text-[16px] leading-none">
                      Explore
                    </div>
                    <img
                      src={iconRight}
                      className="mt-[1px] ml-[10px]"
                      alt="right"
                    />
                  </div>
                </div>

                <img
                  src={rBridge}
                  className="w-[80px] absolute top-[30px] right-[30px]"
                  alt="rBridge"
                />
              </div>

              <div className="ml-[18px] flex w-[330px] h-[291px] rounded-[4px] border border-gray-300 relative">
                <div className="mt-[23px] ml-[20px] mr-[25px]">
                  <div className="text-white font-bold text-[24px] leading-none">
                    rPool
                  </div>

                  <div className="mt-[42px] text-gray-200 text-[20px] leading-none">
                    rPool is an incentive program for rToken mint, rToken LP.
                    Users can mint or stake LP to earn rewards.
                  </div>

                  <div
                    className="absolute bottom-[33px] left-[27px] flex items-center cursor-pointer"
                    onClick={() => openLink("https://app.stafi.io/pool/cosmos")}
                  >
                    <div className="text-[#909FB5] text-[16px] leading-none">
                      Explore
                    </div>
                    <img
                      src={iconRight}
                      className="mt-[1px] ml-[10px]"
                      alt="right"
                    />
                  </div>
                </div>

                <img
                  src={rPool}
                  className="w-[35px] absolute top-[20px] right-[45px]"
                  alt="rPool"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
