import solution1 from "src/assets/images/solution1.svg";
import { openLink } from "src/utils";

export const BuildSection1 = () => {
  return (
    <div className="flex justify-center bg-black-900">
      <div className="w-full-screen h-[486px] pl-[100px] pr-[89px] overflow-hidden">
        <div className="flex justify-between mt-[104px]">
          <div className="relative">
            <div className="w-[650px] text-white font-bold text-[40px] leading-none">
              Integrate rToken Solution
            </div>

            <div className="w-[650px] mt-[35px] text-white text-[18px] leading-tight">
              StaFiHub is built with Cosmos-SDK, mainly providing the solution
              of liquifying staked assets, through IBC, projects can adopt the
              liquid staking solution of StaFiHub and grow together with the
              staking industry.
            </div>

            <div
              className="mt-[67px] w-[144px] h-[42px] border-[1px] border-solid border-red-100 rounded-[2px] flex items-center justify-center text-[16px] font-bold text-red-100 cursor-pointer"
              onClick={() =>
                openLink("https://docs.stafi.io/cosmosintegration/")
              }
            >
              Integrate
            </div>
          </div>

          <div className="flex flex-col ml-[-110px] mt-[-170px]">
            <div className="w-[700px]">
              <img
                src={solution1}
                className="mt-[22px] w-full object-cover"
                alt="sdk1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
