import solution3 from "src/assets/images/solution3.svg";

export const BuildSection5 = () => {
  return (
    <div className="flex justify-center bg-black-900">
      <div className="w-full-screen pl-[100px] pr-[89px] overflow-hidden">
        <div className="flex justify-between">
          <div className="relative">
            <div className="mt-[70px] text-[#909FB5] text-[20px] leading-none">
              MERGE
            </div>

            <div className="mt-[18px] w-[650px] text-white font-bold text-[40px] leading-none">
              Merge into StaFiHub
            </div>

            <div className="w-[600px] mt-[35px] text-[#C0C0C0] text-[20px] leading-tight">
              Staking derivatives built with rToken Solution can send a request
              to the StaFi community. Community will decide the merge. Contact
              support@stafi.io if you need further help.
            </div>
          </div>

          <div className="flex flex-col ml-[-110px] mt-[-150px]">
            <div className="w-[700px]">
              <img
                src={solution3}
                className="mt-[22px] w-full object-cover"
                alt="solution3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
