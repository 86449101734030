import arrowRight from "src/assets/images/arrow_right.svg";
import iconIntegrate1 from "src/assets/images/icon_integrate1.svg";
import iconIntegrate2 from "src/assets/images/icon_integrate2.svg";
import iconIntegrateConnect from "src/assets/images/icon_integrate_connect.svg";
import { openLink } from "src/utils";

export const BuildSection3 = () => {
  return (
    <div className="flex flex-col items-center bg-black-900">
      <div className="relative w-full-screen pl-[100px] pr-[89px] overflow-hidden">
        <div className="relative">
          <div className="z-0 w-[300px] h-[300px] absolute top-[-60px] left-[-60px] object-contain bg-gradient-to-r rounded-full from-red-100 via-[#441E2B] to-black-900 blur-3xl opacity-30" />

          <div className="mt-[54px] text-[#909FB5] text-[20px] leading-none">
            KNOW-HOW
          </div>

          <div className="mt-[20px] text-white font-bold text-[40px] leading-none">
            2-way integrations
          </div>

          <div className="mt-[200px] ml-[34px] flex items-start">
            <div className="self-stretch pt-[30px] h-[600px] pl-[30px] pr-[45px] z-10 bg-[#0E0E0E] rounded-[9px]">
              <img
                className="w-[60px] h-[60px] "
                src={iconIntegrate1}
                alt="integrate"
              />

              <div className="mt-[30px] font-bold text-white text-[30px] leading-tight">
                Integrating with <br />
                Interchain Account
              </div>

              <div className="w-[300px] mt-[30px] text-[#C0C0C0] text-[20px] leading-tight">
                Interchain account is a more decentralized way that enables the
                chain security to maintain rToken among Cosmos-SDK based
                projects.
              </div>

              <div
                className="mt-[145px] flex items-center text-[#C9C9C9] text-[20px] leading-none cursor-pointer"
                onClick={() => {
                  openLink(
                    "https://docs.stafi.io/cosmosintegration/#integrating-with-interchain-accounts"
                  );
                }}
              >
                <div>Read</div>

                <img
                  src={arrowRight}
                  alt="right"
                  className="w-[18px] h-[10px] ml-[10px]"
                />
              </div>
            </div>

            <img
              src={iconIntegrateConnect}
              className="mt-[230px] ml-[-120px]"
              alt="connect"
            />

            <div className="h-[600px] bg-[#0E0E0E] rounded-[9px] self-stretch mt-[128px] ml-[-85px] pt-[30px] pl-[30px] pr-[45px] z-10">
              <img
                className="w-[60px] h-[60px] "
                src={iconIntegrate2}
                alt="integrate"
              />

              <div className="mt-[30px] font-bold text-white text-[30px] leading-tight">
                Integrating with <br />
                Multisign Account
              </div>

              <div className="w-[300px] mt-[30px] text-[#C0C0C0] text-[20px] leading-tight">
                Apply to the projects that haven’t updated to the interchain
                account feature. rToken security relies on the chain security
                and offchain relay.
              </div>

              <div
                className="mt-[145px] flex items-center text-[#C9C9C9] text-[20px] leading-none cursor-pointer"
                onClick={() => {
                  openLink(
                    "https://docs.stafi.io/cosmosintegration/#integrating-with-multisig-accounts-1"
                  );
                }}
              >
                <div>Read</div>

                <img
                  src={arrowRight}
                  alt="right"
                  className="w-[18px] h-[10px] ml-[10px]"
                />
              </div>
            </div>

            <img
              src={iconIntegrateConnect}
              className="mt-[430px] ml-[-70px]"
              alt="connect"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
