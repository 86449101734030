import logo from "src/assets/images/stafihub_logo.png";
import arrowRight from "src/assets/images/arrow_right.svg";
import iconShare from "src/assets/images/icon_share.svg";
import { openLink } from "src/utils";
import { useMatch, useNavigate } from "react-router-dom";
import classNames from "classnames";

export const AppHeader = () => {
  const navigate = useNavigate();
  const matchHome = useMatch("/");
  const matchBuild = useMatch("/build");

  return (
    <div className="flex justify-center">
      <div className="h-[88px] w-full-screen pr-[32px] pl-[18px] flex items-center justify-between">
        <div>
          <img src={logo} className="w-[116px] h-[28px]" alt="logo" />

          <div className="text-[12px] text-white scale-70 origin-top-left">
            Powered by StaFi
          </div>
        </div>

        <div className="flex items-center text-white text-[16px] leading-none">
          <div
            className={classNames("w-12 cursor-pointer", {
              "font-bold text-red-100": matchHome,
            })}
            onClick={() => {
              navigate("/");
            }}
          >
            Learn
          </div>

          <div
            className={classNames("w-12 ml-8 cursor-pointer", {
              "font-bold text-red-100": matchBuild,
            })}
            onClick={() => {
              navigate("/build");
            }}
          >
            Build
          </div>

          <div
            className="ml-8 cursor-pointer"
            onClick={() => {
              openLink("https://github.com/stafihub/stafihub");
            }}
          >
            Github
          </div>

          <div
            className="ml-8 cursor-pointer"
            onClick={() => {
              openLink("https://docs.stafi.io/stafihub/");
            }}
          >
            Docs
          </div>

          <div
            className="ml-8 cursor-pointer"
            onClick={() => {
              openLink("https://discord.com/invite/jB77etn");
            }}
          >
            Discord
          </div>

          <div
            className="ml-8 cursor-pointer flex items-center"
            onClick={() => {
              openLink("https://test-app.stafi.io/gallery/cosmos");
            }}
          >
            <div>Testnet</div>

            <img
              src={iconShare}
              alt="share"
              className="w-[16px] h-[16px] ml-[6px]"
            />
          </div>
        </div>

        <div
          className="flex items-center text-white text-[16px] leading-none cursor-pointer"
          onClick={() => {
            openLink("https://app.stafi.io/gallery/cosmos");
          }}
        >
          <div>APP</div>

          <img
            src={arrowRight}
            alt="right"
            className="w-[18px] h-[10px] ml-[18px]"
          />
        </div>
      </div>
    </div>
  );
};
