import { BuildSection1 } from "src/components/BuildSection1";
import { BuildSection2 } from "src/components/BuildSection2";
import { BuildSection3 } from "src/components/BuildSection3";
import { BuildSection4 } from "src/components/BuildSection4";
import { BuildSection5 } from "src/components/BuildSection5";

export const Build = () => {
  return (
    <div>
      <BuildSection1 />

      <BuildSection2 />

      <BuildSection3 />

      <BuildSection4 />

      <BuildSection5 />
    </div>
  );
};
