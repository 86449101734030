import logo from "src/assets/images/logo_large.svg";
import iconAdd from "src/assets/images/icon_add.svg";

export const Section3 = () => {
  return (
    <div className="flex justify-center bg-black-900 relative">
      <div className="w-[1000px] h-[780px] absolute top-[245px] z-0 object-contain left-1/2 -translate-x-1/2 bg-gradient-to-r from-red-100 via-[#B26BD3] to-red-100 rounded-full blur-3xl opacity-10" />

      <div className="w-full-screen pl-[100px] pr-[89px]">
        <div className="flex justify-enc mt-[84px] relative">
          <img
            src={logo}
            className="mt-[80px] w-[500px] h-[228px] absolute top-0 left-0"
            alt="logo"
          />

          <div className="flex-1 flex flex-col items-end">
            <div className="mt-[76px] text-[#909FB5] text-[20px] leading-none">
              BUILT FOR STAKING DERIVATIVES
            </div>

            <div className="mt-[84px]  text-white font-bold text-[40px] leading-none">
              The First Liquid Staking Solution
            </div>

            <div className=" text-white font-bold text-[40px] leading-none">
              For Projects On Cosmos ECO
            </div>
          </div>
        </div>

        <div className="mt-[70px] flex items-start justify-end">
          <div className="mr-[120px]">
            <img src={iconAdd} className="w-[18px]" alt="add" />

            <div className="mt-[10px] font-bold text-white text-[26px]">
              Derivative Chain
            </div>

            <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
              StaFiHub is a Layer 1 chain built based on the Cosmos SDK
              exclusively for Staking Derivatives. Additionally, we have
              combined the Tendermint consensus along with IBC and staking
              contracts to bring a true liquid staking solution to the Cosmos
              Ecosystem.
            </div>
          </div>

          <div>
            <img src={iconAdd} className="w-[18px]" alt="add" />

            <div className="mt-[10px] font-bold text-white text-[26px]">
              IBC Integration
            </div>

            <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
              StaFiHub is connected to the Cosmos Ecosystem Projects through the
              Inter-Blockchain Communication Protocol (IBC), subsequent rTokens
              may be transferred with utmost security and speed. The IBC will
              expand the use case scenarios for rTokens to a new realm of
              possibilities.
            </div>
          </div>
        </div>

        <div className="mt-[35px] flex items-start justify-end">
          <div className="mr-[120px]">
            <img src={iconAdd} className="w-[18px]" alt="add" />

            <div className="mt-[10px] font-bold text-white text-[26px]">
              Open Structure
            </div>

            <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
              The StaFiHub Chain is built with an open structure for the
              deployment of staking derivatives with minimal cost and effort.
              Future integrations may be decided based on a DAO vote.
            </div>
          </div>

          <div className="mb-[150px]">
            <img src={iconAdd} className="w-[18px]" alt="add" />

            <div className="mt-[10px] font-bold text-white text-[26px]">
              Easy Deployment
            </div>

            <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
              All smart contracts are modularized to create a pain-free
              experience for developers when deploying smart contracts using our
              code samples with little to no modifications.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
