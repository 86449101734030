import light from "src/assets/images/icon_light.svg";

export const Section6 = () => {
  return (
    <div className="flex justify-center pb-[80px]">
      <div className="w-full-screen px-[100px]">
        <div className="flex">
          <div className="mt-[250px]">
            <div className="ml-[40px] text-[80px] font-bold leading-none">
              <span className="text-[#FF7FA7]">Join</span>
              <span className="ml-[20px] text-white">the</span>
              <div className="mt-[10px] text-white">Network</div>
            </div>

            <div className="mt-[28px] w-[434px] text-[20px] text-gray-200 leading-none">
              Roles on StaFiHub such as project developers, validators,
              delegators and liquidity providers are welcome to join our
              network. Please refer to the StaFiHub Documentation for further
              details.
            </div>
          </div>

          <div className="ml-[165px] mt-[150px]">
            <div className="flex items-start">
              <img src={light} className="w-[21px]" alt="light" />
              <div className="ml-[22px]">
                <div className="text-[24px] text-white font-bold leading-none">
                  Become a Developer
                </div>

                <div className="mt-[15px] w-[385px] text-[20px] text-gray-200 leading-none">
                  Build staking derivative with StaFiHub solution, frontend and
                  liquidity KIT.
                </div>
              </div>
            </div>

            <div className="flex items-start mt-[68px]">
              <img src={light} className="w-[21px]" alt="light" />
              <div className="ml-[22px]">
                <div className="text-[24px] text-white font-bold leading-none">
                  Become a Validator
                </div>

                <div className="mt-[15px] w-[415px] text-[20px] text-gray-200 leading-none">
                  Join our decentralized validator program to help secure the
                  network.
                </div>
              </div>
            </div>

            <div className="flex items-start mt-[68px]">
              <img src={light} className="w-[21px]" alt="light" />
              <div className="ml-[22px]">
                <div className="text-[24px] text-white font-bold leading-none">
                  Become a Delegator
                </div>

                <div className="mt-[15px] w-[415px] text-[20px] text-gray-200 leading-none">
                  Delegate your tokens to validators to earn staking rewards
                  while securing the network.
                </div>
              </div>
            </div>

            <div className="flex items-start mt-[68px]">
              <img src={light} className="w-[21px]" alt="light" />
              <div className="ml-[22px]">
                <div className="text-[24px] text-white font-bold leading-none">
                  Become a Liquidity Provider
                </div>

                <div className="mt-[15px] w-[415px] text-[20px] text-gray-200 leading-none">
                  Provide rToken liquidity in rDEX and other rToken DEXs.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
