import features from "src/assets/images/features.svg";
import iconAdd from "src/assets/images/icon_add.svg";
import { openLink } from "src/utils";
import arrowRight from "src/assets/images/arrow_right.svg";

export const BuildSection2 = () => {
  return (
    <div className="flex flex-col items-center bg-black-800 relative">
      <div className="w-full-screen flex pl-[100px] pr-[89px]">
        <div className="relative">
          <div>
            <div className="mt-[100px] text-[#909FB5] text-[20px] leading-none">
              KNOW-WHAT
            </div>

            <div className="mt-[20px]  text-white font-bold text-[40px] leading-none">
              Solution Features
            </div>
          </div>

          <div className="relative flex items-center justify-center w-[350px] h-[350px] top-[145px] z-0">
            <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center  bg-gradient-to-r from-[#6758FF] via-[#B26BD3] to-[#FFD5E2] rounded-full blur-3xl opacity-50"></div>
            <img
              src={features}
              className="w-[270px] h-[270px] z-10"
              alt="features"
            />
          </div>
        </div>

        <div className="z-50 mt-[250px] ml-7">
          <div className="flex">
            <div className="mr-[120px]">
              <img src={iconAdd} className="w-[18px]" alt="add" />

              <div className="mt-[10px] font-bold text-white text-[26px]">
                Modular
              </div>

              <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
                Modular code for easy understanding, development and testing.
              </div>
            </div>

            <div>
              <img src={iconAdd} className="w-[18px]" alt="add" />

              <div className="mt-[10px] font-bold text-white text-[26px]">
                Scalable
              </div>

              <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
                Easily integrate new rTokens, customize configuration.
              </div>
            </div>
          </div>

          <div className="mt-[50px] flex">
            <div className="flex items-start justify-end">
              <div className="mr-[120px]">
                <img src={iconAdd} className="w-[18px]" alt="add" />

                <div className="mt-[10px] font-bold text-white text-[26px]">
                  Interoperable
                </div>

                <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
                  Communicate tokens {"&"} value with other blockchains with the
                  IBC module.
                </div>
              </div>

              <div className="">
                <img src={iconAdd} className="w-[18px]" alt="add" />

                <div className="mt-[10px] font-bold text-white text-[26px]">
                  Secure
                </div>

                <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
                  Decentralized apps and access control ensure security.
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[50px] flex">
            <div className="flex items-start justify-end">
              <div className="mr-[120px]">
                <img src={iconAdd} className="w-[18px]" alt="add" />

                <div className="mt-[10px] font-bold text-white text-[26px]">
                  Open Source
                </div>

                <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
                  Work together to build the free and open source developer
                  community.
                </div>
              </div>

              <div className="">
                <img src={iconAdd} className="w-[18px]" alt="add" />

                <div className="mt-[10px] font-bold text-white text-[26px]">
                  Go
                </div>

                <div className="w-[300px] text-gray-200 text-[20px] leading-tight">
                  Write your service in Go to take advantage of the strong
                  Golang ecosystem.
                </div>
              </div>
            </div>
          </div>

          <div className="absolute z-10 bottom-0 left-0 right-0 bg-black-900 h-[100px]"></div>

          <div className="relative z-50 mt-[100px]">
            <div className="w-[780px] h-[200px] flex items-center rounded-[3px] bg-gradient-to-r from-blue-800 via-[#B26BD3] to-red-100">
              <div className="ml-10">
                <img src={iconAdd} className="w-[18px]" alt="add" />

                <div className="mt-[2px] font-bold text-white text-[36px] leading-tight">
                  Delegation
                </div>
                <div className="font-bold text-white text-[36px] leading-tight">
                  Strategy
                </div>

                <div
                  className="mt-[28px] flex items-center text-[#C9C9C9] text-[20px] leading-none cursor-pointer"
                  onClick={() => {
                    openLink(
                      "https://docs.stafihub.io/welcome-to-stafihub/rtoken/delegation-algorithm"
                    );
                  }}
                >
                  <div>Read</div>

                  <img
                    src={arrowRight}
                    alt="right"
                    className="w-[18px] h-[10px] ml-[10px]"
                  />
                </div>
              </div>

              <div className="ml-[70px] w-[459px] text-[#EEEEEE] text-[20px] leading-tight">
                A more decentralized delegation strategy of the target chain,
                which scores the onchain performance of the validators.
                Automatically delegate, rotate and redelegate.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
