import YouTube, { YouTubeProps } from "react-youtube";

export const VideoSection = () => {
  const opts: YouTubeProps["opts"] = {
    height: 300,
    width: 580,
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <div className="flex justify-center bg-black-800">
      <div className="w-full-screen pl-[100px] pr-[89px] pt-[86px] pb-[60px] flex ">
        <YouTube
          videoId="MtwKdiUO7oc"
          opts={opts}
          onReady={onPlayerReady}
          className="relative border-solid"
        />

        <div className="ml-[40px]">
          <div className="z-10 text-[40px] font-bold leading-none text-white flex items-start flex-col">
            <div>
              Permissionless &amp;
              <br />
              Automatic
            </div>

            <div className="mt-[2px]">Delegation Algorithm</div>
          </div>

          <div className="mt-[35px] text-[18px] leading-tight text-[#c0c0c0] flex items-center flex-col z-10">
            StaFiHub has innovated a smart delegation algorithm that is
            implemented automatically to create maximized staking rewards for
            stakers and decentralized voting power for the POS projects that
            highlights user value and decentralization.
          </div>
        </div>
      </div>
    </div>
  );
};
