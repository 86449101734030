import onChainElection from "src/assets/images/election_validator.svg";
import { openLink } from "src/utils";

export const SectionOnChain = () => {
  return (
    <div className="flex justify-center bg-black-800">
      <div className="w-full-screen pl-[100px] pr-[89px]">
        <div className="flex justify-between mt-[84px]">
          <div>
            <div className="w-[575px] text-white font-bold text-[40px] leading-none">
              On-chain delegation strategy for selecting validator
            </div>

            <div className="w-[472px] mt-[76px] text-gray-200 text-[20px] leading-none">
              The StaFiHub delegation strategy will delegate and redelegate
              stakes to the Original Validator of the target chain according to
              the validators’ on-chain performance score.
            </div>

            <div
              className="mt-[60px] w-[144px] h-[42px] border-[1px] border-solid border-red-100 rounded-[2px] flex items-center justify-center text-[16px] font-bold text-red-100 cursor-pointer"
              onClick={() =>
                openLink(
                  "https://docs.stafihub.io/welcome-to-stafihub/rtoken/delegation-algorithm"
                )
              }
            >
              Learn strategy
            </div>
          </div>

          <img
            src={onChainElection}
            className="ml-[-50px] mb-[-60px] mt-[-120px] w-[860px]"
            alt="onChainElection"
          />
        </div>
      </div>
    </div>
  );
};
