import sdk1 from "src/assets/images/sdk1.svg";
import sdk2 from "src/assets/images/sdk2.svg";
import { openLink } from "src/utils";

export const Section2 = () => {
  return (
    <div className="flex justify-center bg-black-800">
      <div className="w-full-screen pl-[100px] pr-[89px]">
        <div className="mt-[63px]  text-gray-100 text-[20px]">
          BUILT FOR DEVELOPERS
        </div>

        <div className="flex justify-between mt-[84px]">
          <div className="relative">
            <div className="w-[650px] text-white font-bold text-[40px] leading-none">
              The First Staking Derivative Solution Built For Developers
            </div>

            <div className="w-[470px] mt-[76px] text-gray-200 text-[20px] leading-none">
              Projects built based on the Cosmos SDK can quickly deploy its own
              staking derivatives using our rToken solution, which can easily be
              integrated quickly with minimal development costs.
            </div>

            <div
              className="mt-[60px] w-[144px] h-[42px] border-[1px] border-solid border-red-100 rounded-[2px] flex items-center justify-center text-[16px] font-bold text-red-100 cursor-pointer"
              onClick={() => openLink("https://docs.stafi.io/stafihub/")}
            >
              Read Docs
            </div>

            <img
              src={sdk2}
              className="ml-[-100px] mt-[65px] w-[700px] z-10"
              alt="sdk2"
            />
          </div>

          <div className="flex flex-col ml-[-130px] mt-[-130px]">
            <div className="w-[700px]">
              <img
                src={sdk1}
                className="mt-[22px] w-full object-cover"
                alt="sdk1"
              />
            </div>

            <div className="mr-[160px] w-[470px] mt-[-60px] self-end text-right text-white font-bold text-[40px] leading-none">
              Front End Modules For Staking Derivatives
            </div>

            <div className="mr-[160px] mt-[76px] w-[566px] self-end text-right text-gray-200 text-[20px] leading-none">
              <div className="">
                A user friendly modular front-end has been prepared for
              </div>

              <div>
                developers to swiftly deploy staking derivatives which includes
              </div>

              <div>the Staking Module, Onchain-Rate Module, Asset</div>

              <div>Management Module and Reward Distribution Module. All</div>

              <div>modules prepared are designed specifically for staking</div>

              <div>derivatives and will be expanded in the future.</div>
            </div>

            <div
              className="self-end mr-[160px] mt-[60px] w-[144px] h-[42px] border-[1px] border-solid border-[#6758FF] rounded-[2px] flex items-center justify-center text-[16px] font-bold text-[#6758FF] cursor-pointer"
              onClick={() => openLink("https://app.stafi.io/gallery/cosmos")}
            >
              Use APP
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
