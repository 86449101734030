import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import { AppFooter } from "./components/AppFooter";
import { AppHeader } from "./components/AppHeader";
import { Build } from "./pages/Build";
import { Home } from "./pages/Home";

function App() {
  return (
    <div className="App">
      <AppHeader />

      <Routes>
        <Route path="/build" element={<Build />} />

        <Route path="/" element={<Home />} />
      </Routes>

      <AppFooter />
    </div>
  );
}

export default App;
