import solution2 from "src/assets/images/solution2.svg";
import { openLink } from "src/utils";

export const BuildSection4 = () => {
  return (
    <div className="flex justify-center bg-black-900">
      <div className="w-full-screen pl-[100px] pr-[89px] overflow-hidden">
        <div className="mt-[200px] text-[#909FB5] text-[20px] leading-none">
          FRONT-END
        </div>

        <div className="flex justify-between mt-[18px]">
          <div className="relative">
            <div className="w-[650px] text-white font-bold text-[40px] leading-none">
              rToken app
            </div>

            <div className="w-[600px] mt-[35px] text-[#C0C0C0] text-[20px] leading-tight">
              rToken is an app that enables the stakers and validators interact
              with the target chain, users can exchange, stake and redeem. New
              rToken can be easily integrated into the app, developers do not
              need to develop a new front-end app.
            </div>

            <div
              className="mt-[54px] w-[144px] h-[42px] border-[1px] border-solid border-[#6758FF] rounded-[2px] flex items-center justify-center text-[16px] font-bold text-[#6758FF] cursor-pointer"
              onClick={() =>
                openLink(
                  "https://docs.stafihub.io/welcome-to-stafihub/rtoken/integrate-rtoken/integrate-into-rtoken-app"
                )
              }
            >
              Integrate
            </div>
          </div>

          <div className="flex flex-col ml-[-110px] mt-[-250px]">
            <div className="w-[700px]">
              <img
                src={solution2}
                className="mt-[22px] w-full object-cover"
                alt="sdk1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
